@font-face {
  font-family: Knockout;
  font-weight: 400;
  src: url('/fonts/Knockout-28.woff') format('woff');
}

@font-face {
  font-family: Knockout;
  font-weight: 500;
  src: url('/fonts/Knockout-32.woff') format('woff');
}

@font-face {
  font-family: Knockout;
  font-weight: 600;
  src: url('/fonts/Knockout-91.woff') format('woff');
}

:root {
  --bp-red: #cf0a2c;
  --bp-darck-red: #a10f25;
  --bp-black: #292929;
  --bp-grey: #595959;
  --bp-light-rey: #e2e8f0;
  --bp-bedge: #f2edda;
  --bp-white: #fff;
  --bp-blue: #19468d;
  --swiper-pagination-bullet-size: 18px;
  --swiper-pagination-color: #cf0a2c;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: .5;
}

body {
  font-family: Knockout, sans-serif;
}
